import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { DataDictionary } from '@cloudpivot-hermes/data-dictionary';
let SysDataDictionary = class SysDataDictionary extends Vue {
};
SysDataDictionary = __decorate([
    Component({
        name: 'SysDataDictionary',
        components: {
            DataDictionary,
        },
    })
], SysDataDictionary);
export default SysDataDictionary;
